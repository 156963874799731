import {MetaDescriptor, MetaFunction}     from "@remix-run/node";
import {Link, NavLink}                    from "@remix-run/react";
import debounce                           from "lodash.debounce";
import {posthog}                          from "posthog-js";
import {useCallback, useEffect, useState} from "react";
import {Alert, AlertDescription}          from "~/components/ui/alert";
import {Button}                           from "~/components/ui/button";
import {Separator}                        from "~/components/ui/separator";

const ogTags: Array<MetaDescriptor> = [
    {
        property: "og:site_name",
        content : "CHKS • Geavanceerde data-validatie voor professionals",
    },
    {
        property: "og:title",
        content : "CHKS • Geavanceerde data-validatie voor professionals",
    },
    {
        property: "og:url",
        content : "https://chks.tech",
    },
    {
        property: "og:description",
        content : "Verhoog betrouwbaarheid van je data met het CHKS dataverificatie platform. Start vandaag nog gratis.",
    },
    {
        property: "og:image",
        content : "https://chks.tech/preview.png",
    },
    {
        property: "og:image:alt",
        content : "Probeer gratis onze data validatie software via chks.tech",
    },
];

const twitterTags: Array<MetaDescriptor> = [
    {
        property: "twitter:card",
        content : "summary_large_image",
    },
    {
        property: "twitter:title",
        content : "CHKS • Geavanceerde data-validatie voor professionals",
    },
    {
        property: "twitter:site",
        content : "https://chks.tech",
    },
    {
        property: "twitter:description",
        content : "Verhoog betrouwbaarheid van je data met het CHKS dataverificatie platform. Start vandaag nog gratis.",
    },
    {
        property: "twitter:image",
        content : "https://chks.tech/preview.png",
    },
];

export const meta: MetaFunction = () => {
    return [
        {
            title: "CHKS • Geavanceerde data-validatie voor professionals",
        },
        {
            name   : "description",
            content: "Verhoog betrouwbaarheid van je data met het CHKS dataverificatie platform. Start vandaag nog gratis.",
        },
        ...ogTags,
        ...twitterTags,
    ];
};

export default function Index() {
    const [phoneInput, setPhoneInput]                 = useState<boolean>(false);
    const [emailInput, setEmailInput]                 = useState<boolean>(false);
    const [tryingOutAvailable, setTryingOutAvailable] = useState<boolean>(true);

    const [firstNameValid, setFirstNameValid] = useState<boolean>(true);
    const [lastNameValid, setLastNameValid]   = useState<boolean>(true);

    const [firstNameLoading, setFirstNameLoading] = useState<boolean>(false);
    const [lastNameLoading, setLastNameLoading]   = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps,@typescript-eslint/no-unused-vars
    const basicCheck = useCallback(debounce((domain: string, value: string) => {
        type DomainVerificationResponse = {
            verdict: number,
            domain: string
        }

        value = encodeURIComponent(value);

        switch (domain) {
            case "first_name":
                fetch(`https://api.chks.tech/demo/verify/first_name/${value}`)
                    .then(async res => {
                        const response: DomainVerificationResponse = await res.json();

                        if (response.verdict > 0.7) {
                            setFirstNameValid(true);
                        } else {
                            setFirstNameValid(false);
                        }

                        posthog.capture(`landing-input-${domain}`, {response, input: value});
                    })
                    .finally(() => setFirstNameLoading(false));
                break;
            case "last_name":
                fetch(`https://api.chks.tech/demo/verify/last_name/${value}`)
                    .then(async res => {
                        const response: DomainVerificationResponse = await res.json();

                        console.log(response.verdict);

                        if (response.verdict > 0.7) {
                            setLastNameValid(true);
                        } else {
                            setLastNameValid(false);
                        }

                        posthog.capture(`landing-input-${domain}`, {response, input: value});
                    })
                    .finally(() => setLastNameLoading(false));
                break;
            default:
                break;
        }
    }, 250), []);

    const onInput: any = (event: InputEvent) => {
        const element = event.target as HTMLInputElement;

        switch (element.name) {
            case "first_name":
                setFirstNameLoading(true);
                basicCheck("first_name", element.value);
                break;
            case "last_name":
                setLastNameLoading(true);
                basicCheck("last_name", element.value);
                break;
            case "email":
                setEmailInput(true);
                break;
            case "phonenumber":
                setPhoneInput(true);
                break;
            default:
                break;
        }
    };

    const LoadingComponent = useCallback(() => (
        <div
            className="animate-pulse py-3 px-4 border border-purple-900 flex flex-row justify-center lg:justify-start items-center lg:gap-x-2 text-white font-semibold w-full rounded-lg text-sm"
        >
            <svg xmlns="http://www.w3.org/2000/svg" className={"w-5 fill-neutral-400"} viewBox="0 0 24 24">
                <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM7 11V13H17V11H7Z"></path>
            </svg>
            <div className={"hidden lg:flex justify-center items-center w-3/4"}>
                <svg xmlns="http://www.w3.org/2000/svg" className={"w-5"} viewBox="0 0 200 200">
                    <circle
                        fill="#a3a3a3"
                        stroke="#a3a3a3"
                        strokeWidth={15}
                        r={15}
                        cx={40}
                        cy={100}
                    >
                        <animate
                            attributeName="opacity"
                            calcMode="spline"
                            dur={2}
                            values="1;0;1;"
                            keySplines=".5 0 .5 1;.5 0 .5 1"
                            repeatCount="indefinite"
                            begin="-.4"
                        />
                    </circle>
                    <circle
                        fill="#a3a3a3"
                        stroke="#a3a3a3"
                        strokeWidth={15}
                        r={15}
                        cx={100}
                        cy={100}
                    >
                        <animate
                            attributeName="opacity"
                            calcMode="spline"
                            dur={2}
                            values="1;0;1;"
                            keySplines=".5 0 .5 1;.5 0 .5 1"
                            repeatCount="indefinite"
                            begin="-.2"
                        />
                    </circle>
                    <circle
                        fill="#a3a3a3"
                        stroke="#a3a3a3"
                        strokeWidth={15}
                        r={15}
                        cx={160}
                        cy={100}
                    >
                        <animate
                            attributeName="opacity"
                            calcMode="spline"
                            dur={2}
                            values="1;0;1;"
                            keySplines=".5 0 .5 1;.5 0 .5 1"
                            repeatCount="indefinite"
                            begin={0}
                        />
                    </circle>
                </svg>
            </div>
        </div>
    ), []);

    useEffect(() => {
        fetch(`https://api.chks.tech/demo/verify/first_name/edsger`, {
            mode  : "no-cors",
            signal: AbortSignal.timeout(3500),
        }).catch(() => setTryingOutAvailable(false));
    }, []);

    return (
        <div className={"flex flex-col justify-center items-center"}>
            <div className={`py-10 sm:py-24 px-6 lg:px-8 w-full z-10 2xl:w-4/6 backdrop-blur-md`}
                 style={{
                     background      : "url(/noise.svg)",
                     backgroundRepeat: "repeat",
                     backgroundSize  : "contain",
                 }}>
                <div className="container mx-auto flex flex-wrap flex-col md:flex-row items-center">
                    <div className="flex flex-col text-white w-full md:w-2/5 justify-center items-start text-center md:text-left">
                        <p className="uppercase tracking-loose text-neutral-300 w-full">
                            {/*Verspil geen tijd aan foutieve data */}
                            Vanaf 1 december
                        </p>
                        <h2 className="my-4 text-5xl font-bold leading-tight">
                            Zorg voor betrouwbare data.
                        </h2>
                        <p className="leading-normal text-2xl text-neutral-200 mb-8">
                            Controleer gegevens op betrouwbaarheid met onze algoritmes. Privacy gewaarborgd.
                        </p>
                        <Link to={"https://dashboard.chks.tech/register"}
                              className="mx-auto lg:mx-0 hover:underline bg-white text-neutral-950 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                            Start vandaag nog gratis
                        </Link>
                    </div>
                    <div className="w-full md:w-3/5 py-6 text-center flex flex-col justify-start items-center">
                        <div className={"flex flex-row justify-center items-center gap-x-0.5 mb-4"}>
                            <svg xmlns="http://www.w3.org/2000/svg" className={"w-10"} viewBox="0 0 200 200">
                                <path
                                    fill="#fd2899"
                                    stroke="#fd2899"
                                    strokeWidth={15}
                                    style={{
                                        transformOrigin: "center",
                                    }}
                                    d="m148 84.7 13.8-8-10-17.3-13.8 8a50 50 0 0 0-27.4-15.9v-16h-20v16A50 50 0 0 0 63 67.4l-13.8-8-10 17.3 13.8 8a50 50 0 0 0 0 31.7l-13.8 8 10 17.3 13.8-8a50 50 0 0 0 27.5 15.9v16h20v-16a50 50 0 0 0 27.4-15.9l13.8 8 10-17.3-13.8-8a50 50 0 0 0 0-31.7Zm-47.5 50.8a35 35 0 1 1 0-70 35 35 0 0 1 0 70Z"
                                >
                                    <animateTransform
                                        type="rotate"
                                        attributeName="transform"
                                        calcMode="spline"
                                        dur={2}
                                        values="0;120"
                                        keyTimes="0;1"
                                        keySplines="0 0 1 1"
                                        repeatCount="indefinite"
                                    />
                                </path>
                            </svg>
                            <h4 className={"text-neutral-100 font-medium text-3xl"}>
                                Probeer het zelf
                            </h4>
                        </div>

                        {!tryingOutAvailable ? (
                            <Alert variant={"default"} className={"bg-white mb-6 max-w-sm"}>
                                <AlertDescription className={"text-left text-pink-700"}>
                                    Door populariteit tijdelijk niet beschikbaar.
                                </AlertDescription>
                            </Alert>
                        ) : null}

                        <div className={"flex flex-row sm:gap-x-4"}>
                            <div className={"flex flex-col gap-y-4"}>
                                <div className="max-w-sm space-y-3">
                                    <input type="text"
                                           onInput={onInput}
                                           name={"first_name"}
                                           disabled={!tryingOutAvailable}
                                           className="caret-pink-600 outline-pink-600 py-3 px-4 bg-neutral-50 disabled:text-neutral-400 block w-full border-neutral-200 rounded-lg text-[16px] focus:border-pink-500 focus:ring-pink-500"
                                           defaultValue={"Edsger"}
                                           autoCapitalize={"words"}
                                           autoComplete={"given-name"}
                                           placeholder="Voornaam"/>
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <input type="text"
                                           onInput={onInput}
                                           name={"last_name"}
                                           disabled={!tryingOutAvailable}
                                           className="caret-pink-600 outline-pink-600 py-3 px-4 bg-neutral-50 disabled:text-neutral-400 block w-full border-neutral-200 rounded-lg text-[16px] focus:border-pink-500 focus:ring-pink-500"
                                           defaultValue={"Dijkstra"}
                                           autoCapitalize={"words"}
                                           autoComplete={"family-name"}
                                           placeholder="Achternaam"/>
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <input type="email"
                                           onInput={onInput}
                                           name={"email"}
                                           autoCapitalize={"off"}
                                           autoComplete={"email"}
                                           defaultValue={"e.dijkstra@example.com"}
                                           className="caret-pink-600 outline-pink-600 py-3 px-4 bg-neutral-50 disabled:text-neutral-400  block w-full border-neutral-200 rounded-lg text-[16px] focus:border-pink-500 focus:ring-pink-500"
                                           placeholder="E-mailadres"/>
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <input type="tel"
                                           onInput={onInput}
                                           name={"phonenumber"}
                                           disabled={!tryingOutAvailable}
                                           autoComplete={"tel"}
                                           defaultValue={"+31647889911"}
                                           className="caret-pink-600 outline-pink-600 py-3 px-4 bg-neutral-50 disabled:text-neutral-400  block w-full border-neutral-200 rounded-lg text-[16px] focus:border-pink-500 focus:ring-pink-500"
                                           placeholder="Telefoonnummer"/>
                                </div>
                            </div>

                            <div className={"mx-1"}/>

                            <div className={"flex flex-col gap-y-[1.1rem] lg:w-1/2"}>
                                <div className="w-full space-y-3">
                                    {firstNameLoading ? (<LoadingComponent/>) : (<>
                                        {firstNameValid ? (
                                            <div
                                                className="py-3 px-4 border border-purple-500 flex flex-row justify-start items-center gap-x-2 text-white font-semibold w-full rounded-lg text-sm"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className={"w-5 fill-lime-400"} viewBox="0 0 24 24">
                                                    <path
                                                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"></path>
                                                </svg>
                                                <div className={"hidden lg:block"}>Betrouwbaar</div>
                                            </div>
                                        ) : (
                                             <div
                                                 className="py-3 px-4 border border-red-500 flex flex-row justify-start items-center gap-x-2 text-white font-semibold w-full rounded-lg text-sm"
                                             >
                                                 <svg xmlns="http://www.w3.org/2000/svg" className={"w-5 fill-red-500"} viewBox="0 0 24 24">
                                                     <path
                                                         d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"></path>
                                                 </svg>
                                                 <div className={"hidden lg:block"}>Onbetrouwbaar</div>
                                             </div>
                                         )}
                                    </>)}
                                </div>

                                <div className="w-full space-y-3">
                                    {lastNameLoading ? (<LoadingComponent/>) : (<>
                                        {lastNameValid ? (
                                            <div
                                                className="py-3 px-4 border border-purple-500 flex flex-row justify-start items-center gap-x-2 text-white font-semibold w-full rounded-lg text-sm"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className={"w-5 fill-lime-400"} viewBox="0 0 24 24">
                                                    <path
                                                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"></path>
                                                </svg>
                                                <div className={"hidden lg:block"}>Betrouwbaar</div>
                                            </div>
                                        ) : (
                                             <div
                                                 className="py-3 px-4 border border-red-500 flex flex-row justify-start items-center gap-x-2 text-white font-semibold w-full rounded-lg text-sm"
                                             >
                                                 <svg xmlns="http://www.w3.org/2000/svg" className={"w-5 fill-red-500"} viewBox="0 0 24 24">
                                                     <path
                                                         d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"></path>
                                                 </svg>
                                                 <div className={"hidden lg:block"}>Onbetrouwbaar</div>
                                             </div>
                                         )}
                                    </>)}
                                </div>

                                <div className="w-full space-y-3">
                                    {emailInput ? (
                                        <div
                                            className="py-3 px-4 border border-purple-500 flex flex-row justify-start items-center gap-x-2 text-white font-semibold w-full rounded-lg text-sm"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={"w-5 fill-yellow-400"}>
                                                <path
                                                    d="M12.0049 22.0027C6.48204 22.0027 2.00488 17.5256 2.00488 12.0027C2.00488 6.4799 6.48204 2.00275 12.0049 2.00275C17.5277 2.00275 22.0049 6.4799 22.0049 12.0027C22.0049 17.5256 17.5277 22.0027 12.0049 22.0027ZM10.0549 11.0027C10.2865 9.86163 11.2954 9.00275 12.5049 9.00275C13.1201 9.00275 13.6834 9.22496 14.1189 9.59351L15.8198 8.45951C14.9973 7.56402 13.8166 7.00275 12.5049 7.00275C10.1886 7.00275 8.28107 8.75277 8.03235 11.0027H7.00488V13.0027H8.03235C8.28107 15.2527 10.1886 17.0027 12.5049 17.0027C13.8166 17.0027 14.9973 16.4415 15.8198 15.546L14.1188 14.412C13.6833 14.7806 13.1201 15.0027 12.5049 15.0027C11.2954 15.0027 10.2865 14.1439 10.0549 13.0027H15.0049V11.0027H10.0549Z"></path>
                                            </svg>
                                            <Link to={"https://dashboard.chks.tech"} className={"hidden lg:block"}>
                                                Account vereist
                                            </Link>
                                        </div>
                                    ) : (
                                         <div
                                             className="py-3 px-4 border border-red-500 flex flex-row justify-start items-center gap-x-2 text-white font-semibold w-full rounded-lg text-sm"
                                         >
                                             <svg xmlns="http://www.w3.org/2000/svg" className={"w-5 fill-red-500"} viewBox="0 0 24 24">
                                                 <path
                                                     d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"></path>
                                             </svg>
                                             <div className={"hidden lg:block"}>Onbetrouwbaar</div>
                                         </div>
                                     )}
                                </div>

                                <div className="w-full space-y-3">
                                    {phoneInput ? (
                                        <div
                                            className="py-3 px-4 border border-purple-500 flex flex-row justify-start items-center gap-x-2 text-white font-semibold w-full rounded-lg text-sm"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={"w-5 fill-yellow-400"}>
                                                <path
                                                    d="M12.0049 22.0027C6.48204 22.0027 2.00488 17.5256 2.00488 12.0027C2.00488 6.4799 6.48204 2.00275 12.0049 2.00275C17.5277 2.00275 22.0049 6.4799 22.0049 12.0027C22.0049 17.5256 17.5277 22.0027 12.0049 22.0027ZM10.0549 11.0027C10.2865 9.86163 11.2954 9.00275 12.5049 9.00275C13.1201 9.00275 13.6834 9.22496 14.1189 9.59351L15.8198 8.45951C14.9973 7.56402 13.8166 7.00275 12.5049 7.00275C10.1886 7.00275 8.28107 8.75277 8.03235 11.0027H7.00488V13.0027H8.03235C8.28107 15.2527 10.1886 17.0027 12.5049 17.0027C13.8166 17.0027 14.9973 16.4415 15.8198 15.546L14.1188 14.412C13.6833 14.7806 13.1201 15.0027 12.5049 15.0027C11.2954 15.0027 10.2865 14.1439 10.0549 13.0027H15.0049V11.0027H10.0549Z"></path>
                                            </svg>
                                            <div className={"hidden lg:block"}>Account vereist</div>
                                        </div>
                                    ) : (
                                         <div
                                             className="py-3 px-4 border border-purple-500 flex flex-row justify-start items-center gap-x-2 text-white font-semibold w-full rounded-lg text-sm">
                                             <svg xmlns="http://www.w3.org/2000/svg" className={"w-5 fill-lime-400"} viewBox="0 0 24 24">
                                                 <path
                                                     d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"></path>
                                             </svg>
                                             <div className={"hidden lg:block"}>Betrouwbaar</div>
                                         </div>
                                     )}
                                </div>
                            </div>
                        </div>

                        <Separator className={"bg-purple-800 w-4/5 sm:w-3/5 md:w-4/5 xl:w-3/5 2xl:w-4/5 3xl:w-2/5 my-2 -mb-2"}/>

                        <div className="flex flex-row justify-center items-center sm:mx-32 md:mx-10 lg:mx-16 xl:mx-32 2xl:mx-14 3xl:mx-44 4xl:mx-80">
                            <label htmlFor={"postal-code"} className={"py-3 pr-1 block w-3/4"}>
                                <p className={"text-white text-left mb-0.5"}>
                                    Postcode
                                </p>
                                <input type="text"
                                       name={"postal_code"}
                                       disabled={true}
                                       autoComplete={"postal-code"}
                                       defaultValue={"6512 AB"}
                                       className="w-full h-12 px-4 caret-pink-600 outline-pink-600 bg-neutral-50 border-neutral-200 rounded-lg text-[16px] focus:border-pink-500 focus:ring-pink-500"
                                       placeholder="Postcode"/>
                            </label>
                            <label htmlFor="house_number" className={"py-3 pl-1 block w-1/4"}>
                                <p className={"text-white text-left mb-0.5"}>
                                    Huisnr.
                                </p>
                                <input type="text"
                                       name={"house_number"}
                                       defaultValue={"6"}
                                       disabled={true}
                                       className="w-full h-12 px-4 caret-pink-600 outline-pink-600 bg-neutral-50 border-neutral-200 rounded-lg text-[16px] focus:border-pink-500 focus:ring-pink-500"
                                       placeholder="Huisnr."/>
                            </label>
                        </div>

                        <div style={{
                            backgroundColor: "rgba(58,14,178,0.2)",
                        }}
                             className={"w-full sm:w-3/5 md:w-4/5 xl:w-3/5 2xl:w-4/5 3xl:w-1/4 border border-purple-500 rounded flex justify-center items-center py-2 mt-2"}>
                            <div className={"text-white text-left font-medium"}>
                                <span className={"text-purple-300"}>&#x2794;</span> Stationsplein 6<br/>
                                <span className={"text-purple-300"}>&#x2794;</span> 6512AB Nijmegen <br/>
                                <span className={"text-purple-300"}>&#x2794;</span> Gelderland, Nederland <br/>
                            </div>
                        </div>

                        <p className={"text-neutral-200 mt-2"}>+ <b>API toegang</b> en meer checks bij <b>basis</b> plan</p>
                    </div>
                </div>

            </div>

            <section className="w-full 2xl:w-4/6 py-8 bg-purple-700 shadow-inner mt-24 rounded-2xl"
                     style={{
                         background      : "url(/bg-2.svg)",
                         backgroundRepeat: "repeat",
                         backgroundSize  : "25%",
                     }}>
                <div className="container max-w-5xl mx-auto m-8">
                    <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white opacity-90">
                        Jij hebt dit nodig
                    </h2>
                    <div className="w-full mb-4">
                        <div className="h-1 mx-auto bg-gradient-to-r from-pink-400 via-pink-600 to-indigo-500 w-64 opacity-25 my-0 py-0 rounded-t"/>
                    </div>
                    <div className="flex flex-wrap">
                        <div className="w-5/6 sm:w-1/2 p-6">
                            <h3 className="text-3xl text-neutral-300 font-bold leading-none mb-3">
                                Wanneer is data betrouwbaar?
                            </h3>
                            <p className="text-neutral-300 mb-8">
                                Wij analyseren de gegevens met een aantal algoritmes die kunnen beredeneren gegevens door een persoon <i>oprecht</i> is
                                ingevoerd
                                of dat de
                                gebruiker nepgegevens heeft ingevoerd.
                                <br/>
                                <br/>
                                <b>Jan</b> is waarschijnlijk echt en <b>Qwerty</b> is waarschijnlijk nep.
                                <br/>
                                <br/>
                                Klinkt simpel, toch? Maar in de praktijk is dat vaak niet zo.
                                <br/>
                                <br/>
                                <b>Voorbeeld</b>: iemand voert als achternaam <b>{"\""}van{"\""}</b> in, is dit nep of is deze persoon iets vergeten?
                                Die kans bestaat, maar {"\""}van{"\""} is een <Link className={"underline"} target={"_blank"}
                                                                                    to={"https://www.cbgfamilienamen.nl/nfb/detail_naam.php?gba_lcnaam=van&gba_naam=Van&nfd_naam=Van&operator=eq&taal="}>bestaande
                                achternaam</Link>.
                            </p>
                        </div>
                        <div className="w-full sm:w-1/2 p-6">
                            <img className="w-full sm:h-64 mx-auto" src="/illu1.svg" alt=""/>
                        </div>
                    </div>
                    <div className="flex flex-wrap flex-col-reverse sm:flex-row">
                        <div className="w-full sm:w-1/2 p-6 mt-6">
                            <img className="w-full sm:h-64 mx-auto" src="/illu2.svg" alt=""/>
                        </div>
                        <div className="w-full sm:w-1/2 p-6 mt-6">
                            <div className="align-middle">
                                <h3 className="text-3xl text-neutral-300 font-bold leading-none mb-3">
                                    Privacy gewaarborgd
                                </h3>
                                <p className="text-neutral-300 mb-8">
                                    Wij nemen privacy zeer serieus. Zo serieus dat wij nooit data opslaan.
                                    Zo blijft de data van jou en je klant veilig en blijft het conform AVG.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="w-full 2xl:w-4/6 py-8">
                <a id="pricing" href="#pricing" className={"hidden"}>-</a>
                <div className="container mx-auto px-2 pt-4 pb-12 text-neutral-800">
                    <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
                        Prijzen
                    </h2>
                    <div className="w-full mb-4">
                        <div className="h-1 mx-auto bg-gradient-to-r from-pink-400 via-pink-600 to-indigo-500 w-64 opacity-25 my-0 py-0 rounded-t"/>
                    </div>
                    <div className="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
                        <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-l mt-4"
                             style={{
                                 background      : "#571474 url(/noise.svg)",
                                 backgroundRepeat: "repeat",
                                 backgroundSize  : "contain",
                             }}>
                            <div className="flex-1 rounded-t rounded-b-none overflow-hidden">
                                <div className="p-8 text-3xl text-white font-bold text-center border-b-2 border-purple-400">
                                    Basis
                                </div>
                                <ul className="w-full text-center text-purple-200 text-sm">
                                    <li className="border-b border-neutral-300 py-4">Standaard checks</li>
                                    <li className="border-b border-neutral-300 py-4">TBD verzoeken per dag</li>
                                    <li className="border-b border-neutral-300 py-4">Alleen handmatig</li>
                                    <li className="border-b border-neutral-300 py-4">Geen support</li>
                                </ul>
                            </div>
                            <div className="flex-none mt-auto rounded-b rounded-t-none overflow-hidden shadow p-6">
                                <div className="w-full pt-6 text-3xl text-neutral-200 font-bold text-center">
                                    Gratis
                                </div>
                                <div className="flex items-center justify-center">
                                    <Button
                                        className="mx-auto lg:mx-0 hover:underline my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                                        asChild={true}>
                                        <NavLink to={"/register"}>
                                            Starten
                                        </NavLink>
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg mt-4 sm:-mt-6 shadow-lg z-10"
                             style={{
                                 background      : "#fff url(/noise.svg)",
                                 backgroundRepeat: "repeat",
                                 backgroundSize  : "contain",
                             }}>
                            <div className="flex-1 rounded-t rounded-b-none overflow-hidden shadow">
                                <div className="w-full text-neutral-950 p-8 text-4xl font-extrabold text-center">
                                    Starter
                                </div>
                                <div className="h-1 w-full bg-gradient-to-r from-pink-400 via-pink-600 to-indigo-500 my-0 py-0 rounded-t"/>
                                <ul className="w-full text-purple-950 text-center text-base font-semibold">
                                    <li className="border-b py-4">
                                        Uitgebreide checks
                                    </li>
                                    <li className="border-b py-4">
                                        TBD verzoeken per dag
                                    </li>
                                    <li className="border-b py-4">
                                        Handmatig + API toegang
                                    </li>
                                    <li className="border-b py-4">
                                        Basis support
                                    </li>
                                </ul>
                            </div>
                            <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                                <div className="w-full pt-6 text-neutral-950 text-4xl font-bold text-center">
                                    TBD
                                    <span className="text-base">/ per maand</span>
                                </div>
                                <div className="flex items-center justify-center">
                                    <Button size={"lg"}
                                            className="mx-auto lg:mx-0 hover:underline bg-gradient-to-r from-pink-400 via-pink-600 to-indigo-500 text-white font-bold my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                                            asChild={true}>
                                        <NavLink to={"/register"}>
                                            Starten
                                        </NavLink>
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-l mt-4"
                             style={{
                                 background      : "#571474 url(/noise.svg)",
                                 backgroundRepeat: "repeat",
                                 backgroundSize  : "contain",
                             }}>
                            <div className="flex-1 rounded-t rounded-b-none overflow-hidden">
                                <div className="p-8 text-3xl text-white font-bold text-center border-b-2 border-purple-400">
                                    Zakelijk
                                </div>
                                <ul className="w-full text-center text-purple-200 text-sm">
                                    <li className="border-b border-neutral-300 py-4">Geavanceerde checks</li>
                                    <li className="border-b border-neutral-300 py-4">TBD verzoeken per dag</li>
                                    <li className="border-b border-neutral-300 py-4">Handmatig + API toegang</li>
                                    <li className="border-b border-neutral-300 py-4">24/7 support</li>
                                </ul>
                            </div>
                            <div className="flex-none mt-auto rounded-b rounded-t-none overflow-hidden shadow p-6">
                                <div className="w-full pt-6 text-3xl text-neutral-200 font-bold text-center">
                                    TBD
                                    <span className="text-base">/ per maand</span>
                                </div>
                                <div className="flex items-center justify-center">
                                    <Button
                                        className="mx-auto lg:mx-0 hover:underline my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                                        asChild={true}>
                                        <NavLink to={"/register"}>
                                            Starten
                                        </NavLink>
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <svg className="wave-top" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <g transform="translate(-1.000000, -14.000000)" fillRule="nonzero">
                        <g className="wave">
                            <path
                                d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"/>
                        </g>
                        <g transform="translate(1.000000, 15.000000)" fill="#ff00f8">
                            <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                                <path
                                    d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                                    opacity="0.100000001"/>
                                <path
                                    d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                                    opacity="0.100000001"/>
                                <path
                                    d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                                    opacity="0.200000003"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
}
